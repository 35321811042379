import React, { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from "react";

import { getAllIssueComments, showFlag } from "../services/jira-api";
import { Comment } from "../types";
import { getErrorMessage } from "../utils";

type CommentsStore = Record<string, Comment[]>;

interface CommentsStoreData {
  commentsByIssueKey: CommentsStore;
  fetchIssueComments: (issueId: string) => Promise<void>;
}

const CommentsStoreContext = createContext<CommentsStoreData | null>(null);

export function CommentsStoreProvider({ children }: PropsWithChildren<object>) {
  const [commentsByIssueKey, setCommentsByIssueKey] = useState<CommentsStore>({});

  const fetchIssueComments = useCallback(async (issueId: string) => {
    try {
      const comments = await getAllIssueComments(issueId);
      setCommentsByIssueKey((prev) => ({ ...prev, [issueId]: comments }));
    } catch (error) {
      const message = getErrorMessage(error);
      console.error("Failed to fetch issue comments", message);
      showFlag("Failed to fetch issue comments", message, "error");
    }
  }, []);

  const storeContext = useMemo(
    () => ({
      commentsByIssueKey,
      fetchIssueComments,
    }),
    [commentsByIssueKey, fetchIssueComments],
  );

  return <CommentsStoreContext.Provider value={storeContext}>{children}</CommentsStoreContext.Provider>;
}

export function useCommentsStore() {
  const context = useContext(CommentsStoreContext);
  if (!context) {
    throw new Error("Comments store context not initialized, probably context provider is missing");
  }
  return context;
}
