import { useCallback, useEffect, useMemo, useState } from "react";

import { useBacklogStore } from "../../providers/BacklogStoreContextProvider";
import { getIssuesByJql } from "../../services/jira-api";
import { JiraIssue } from "../../types";

const BATCH_SIZE = 100;

export function useQueryItemsByJQL(columnsIds: string[], jql?: string) {
  const [issues, setIssues] = useState<JiraIssue[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string | undefined>(undefined);
  const [pageIndexToLoad, setPageIndexToLoad] = useState(0);
  const [pagesLoaded, setPagesLoaded] = useState(0);
  const [loading, setLoading] = useState(false);
  const { backlogIssues } = useBacklogStore();

  useEffect(() => {
    setIssues([]);
    setNextPageToken(undefined);
    setPageIndexToLoad(0);
    setPagesLoaded(0);
  }, [jql]);

  useEffect(() => {
    const shouldLoadMore = !loading && pagesLoaded == pageIndexToLoad;
    void (async () => {
      if (jql && shouldLoadMore) {
        try {
          setLoading(true);
          const queryParams = new URLSearchParams({
            jql,
            maxResults: String(BATCH_SIZE),
            fields: columnsIds.join(","),
            ...(nextPageToken ? { nextPageToken } : {}),
          });
          const { issues: newIssues, nextPageToken: newNextPageToken } = await getIssuesByJql(queryParams.toString());
          setIssues((prev) => [...prev, ...newIssues]);
          setNextPageToken(newNextPageToken);
        } catch (error) {
          console.error(error);
        } finally {
          setPagesLoaded((prev) => prev + 1);
          setLoading(false);
        }
      }
    })();
  }, [jql, columnsIds, nextPageToken, issues, loading, pageIndexToLoad, pagesLoaded]);

  const loadNextPage = useCallback(() => {
    setPageIndexToLoad((prev) => prev + 1);
  }, []);

  const updatedIssues = useMemo(() => {
    return issues.map((issue) => backlogIssues[issue.key] || issue);
  }, [issues, backlogIssues]);

  return {
    issues: updatedIssues,
    loading,
    loadNextPage,
    hasMore: updatedIssues.length < 1000 && Boolean(nextPageToken),
    setIssues,
  };
}
