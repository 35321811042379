import React, { ChangeEvent, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";

import Avatar from "@atlaskit/avatar";
import Button, { LoadingButton } from "@atlaskit/button";
import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
import ChevronRightIcon from "@atlaskit/icon/glyph/chevron-right";
import CommentIcon from "@atlaskit/icon/glyph/comment";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import TextArea from "@atlaskit/textarea";
import { Box } from "@fuegokit/react";

import "./Comments.css";

import { useApplicationContext } from "../providers/ApplicationContextProvider";
import { useCommentsStore } from "../providers/CommentsStoreProvider";
import { useUsersStore } from "../providers/UserStoreContextProvider";
import { addIssueComment, showFlag } from "../services/jira-api";
import { Attachment, Comment, JiraIssue, JiraUser } from "../types";
import { getErrorMessage } from "../utils";
import { JiraHtmlRenderer } from "./Description";
import { CollapsiblePanel } from "./game/IssueDetails/CollapsiblePanel";

function CommentCounter({ count }: { count: number }) {
  return (
    <Box className="comment-counter">
      <CommentIcon size="medium" label="comment" />
      <span>{count}</span>
    </Box>
  );
}

function CommentsExpander({ count, children }: PropsWithChildren<{ count: number }>) {
  const [isExpanded, setIsExpanded] = useState(false);
  const icon = isExpanded ? <ChevronDownIcon label="chevron" /> : <ChevronRightIcon label="chevron" />;
  return (
    <>
      <Box className="comments-expander" onClick={() => setIsExpanded((prev) => !prev)}>
        <CommentIcon label="comment" />
        <span className="title">{`${isExpanded ? "Hide" : "Show"} (${count}) comments`}</span>
        {icon}
      </Box>
      {isExpanded && <div className="comments-list">{children}</div>}
    </>
  );
}

export function Comments({
  issue,
  isExpanded,
  toggle,
}: {
  issue: JiraIssue;
  isExpanded: boolean;
  toggle: VoidFunction;
}) {
  const { commentsByIssueKey, fetchIssueComments } = useCommentsStore();
  const comments = useMemo<Comment[] | undefined>(() => commentsByIssueKey[issue.id], [commentsByIssueKey, issue.id]);
  const [first, ...rest] = useMemo(() => [...(comments ?? [])].reverse(), [comments]);
  const attachments = useMemo(() => issue.fields.attachment || [], [issue.fields.attachment]);

  const refreshComments = useCallback(() => fetchIssueComments(issue.id), [fetchIssueComments, issue.id]);

  const hasMore = rest.length > 0;

  return (
    <CollapsiblePanel
      isExpanded={isExpanded}
      toggle={toggle}
      title="Comments"
      alignItems="center"
      icon={comments ? <CommentCounter count={comments?.length} /> : undefined}
    >
      <Box p="16px" className="comments">
        {first ? (
          <>
            <Comment key={first.id} attachments={attachments} comment={first} />
            {hasMore && (
              <CommentsExpander count={rest.length}>
                {rest.map((comment) => {
                  return <Comment key={comment.id} attachments={attachments} comment={comment} />;
                })}
              </CommentsExpander>
            )}
          </>
        ) : null}

        <AddComment issueKey={issue.key} reFetchIssue={refreshComments} />
      </Box>
    </CollapsiblePanel>
  );
}

function Comment({ comment, attachments }: { comment: Comment; attachments: Attachment[] }) {
  const author = comment.author;
  return (
    <>
      <div className="details flex-align-center" data-private>
        <Box className="flex-align-center" flexShrink={0}>
          <Box display="flex" mr="4px">
            <Avatar size="small" src={author.avatarUrls["24x24"]} />
          </Box>
          <span className="display-name">{author.displayName}</span>
        </Box>
        <div className="created">{comment.created}</div>
      </div>
      <div className="comment-body" data-private>
        <JiraHtmlRenderer text={comment.renderedBody} attachments={attachments} />
      </div>
    </>
  );
}

function AddComment({ issueKey, reFetchIssue }: { issueKey: string; reFetchIssue: () => Promise<void> }) {
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState<JiraUser>();
  const { queryByAccountIds } = useUsersStore();
  const { userAccountId } = useApplicationContext();

  const onChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  }, []);

  const addComment = useCallback(async () => {
    try {
      setIsLoading(true);
      const largeComment = comment.repeat(2 * 1000);
      await addIssueComment(issueKey, largeComment);
      await reFetchIssue();
      setComment("");
      showFlag("Comment saved", `Comment saved to issue ${issueKey}`, "info");
      setIsFormOpen(false);
    } catch (e) {
      showFlag("Failed to save comment", ` ${getErrorMessage(e)}`, "error");
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [issueKey, comment, reFetchIssue]);

  const onSubmit = useCallback(() => {
    void addComment();
  }, [addComment]);

  const onCancel = useCallback(() => {
    setIsFormOpen(false);
    setComment("");
  }, []);

  useEffect(() => {
    void (async () => {
      const [currentUser] = await queryByAccountIds([userAccountId]);
      setCurrentUser(currentUser);
    })();
  }, [userAccountId, queryByAccountIds]);

  if (isFormOpen) {
    return (
      <Box display="flex">
        <Box mr="4px">
          <Avatar size="small" src={currentUser?.avatarUrls["24x24"]} data-private />
        </Box>
        <Box flexGrow={1}>
          <TextArea autoFocus={true} value={comment} onChange={onChange} data-private />
          <div className="buttons-group">
            <Button onClick={onCancel}>Cancel</Button>
            <LoadingButton
              isLoading={isLoading}
              isDisabled={!comment}
              appearance={comment ? "primary" : "default"}
              onClick={onSubmit}
            >
              Save
            </LoadingButton>
          </div>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box className="new-comment flex-align-center" onClick={() => setIsFormOpen(true)}>
        <EditorAddIcon label="add comment" />
        <span>New comment</span>
      </Box>
    );
  }
}
