import React, { MouseEvent, useMemo, useState } from "react";

import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import { Box } from "@fuegokit/react";

import "./Description.css";

import { useApplicationContext } from "../providers/ApplicationContextProvider";
import { Attachment, JiraIssue } from "../types";
import { parseHtmlFromString } from "../utils/jira-renderer";
import { Cover } from "./Attachments";
import { CollapsiblePanel } from "./game/IssueDetails/CollapsiblePanel";

const extractImageFilenameFromClick = (e: MouseEvent<HTMLElement>): string | undefined => {
  const imgElement = (e.target as HTMLElement).closest("img");
  if (imgElement?.parentElement?.classList.contains("image-wrap")) {
    return imgElement?.alt;
  }
  return undefined;
};

export function JiraHtmlRenderer({ text, attachments }: { text: string; attachments: Attachment[] }) {
  const { hostBaseUrl } = useApplicationContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [clickedImageAttachment, setClickedImageAttachment] = useState<Attachment | undefined>();

  const html = useMemo(
    () =>
      parseHtmlFromString({
        text,
        baseURL: hostBaseUrl,
        attachments,
      }),
    [text, hostBaseUrl, attachments],
  );

  const onClickHtmlElement = (e: MouseEvent<HTMLElement>) => {
    const imageFilename = extractImageFilenameFromClick(e);
    const image = attachments.find((a) => a.filename === imageFilename);
    if (image) {
      setClickedImageAttachment(image);
      setIsOpen(true);
    }
  };

  return (
    <Box className="renderer" onClick={onClickHtmlElement}>
      <ModalTransition>
        {isOpen && clickedImageAttachment && (
          <Modal width="auto" onClose={() => setIsOpen(false)}>
            <Cover attachment={clickedImageAttachment} controls={true} />
          </Modal>
        )}
      </ModalTransition>
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
    </Box>
  );
}

export function Description({
  issue,
  isExpanded,
  toggle,
}: {
  issue: JiraIssue;
  isExpanded: boolean;
  toggle: VoidFunction;
}) {
  const attachments = useMemo(() => issue.fields.attachment || [], [issue.fields.attachment]);
  return (
    <CollapsiblePanel
      isExpanded={isExpanded}
      toggle={toggle}
      title="Description"
      secondaryText={issue.renderedFields.description}
    >
      {issue.renderedFields.description ? (
        <Box py="12px" px="16px">
          <JiraHtmlRenderer text={issue.renderedFields.description} attachments={attachments} />
        </Box>
      ) : null}
    </CollapsiblePanel>
  );
}
